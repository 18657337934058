@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Titillium+Web&display=swap');

body {
    /*margin: 0 30px;*/
    font-family: 'Titillium Web', sans-serif;
    background: #e8e5e5;
}

h1,
h2,
h3,
h4 {
    font-weight: 500;
    margin: auto;
}

.OutContainer {
    font-family: 'Roboto', sans-serif;
    height: 100vh;
    display: grid;
    grid-template-columns: minmax(15%, 15%) auto;
    padding: 0;
    margin: 0;
    background: #f5f8fc;
    /*border: dashed #1a83ff;*/
}

.sidebar {
    height: 100vh;
    width: 15vw;
    font-size: 2rem;
    text-align: center;
    background: #eeeeee;
    border-right: solid #e5e5eb 1px;
    grid-template-rows: 60px 1fr 0;
    /* border: #031d4d solid 1px; */
}

.App {
    /*padding: 2rem;*/
    /*margin: auto;*/
    /*max-width: 1200px;*/
    /*padding-top: 20px;*/
    /*box-sizing: border-box;*/
    display: grid;
    height: 100vh;
    width: 85vw;
    grid-template-rows: 62px auto;
    /* grid-template-rows: 1fr; */
    font-size: 0.9em;
    /*border: dashed #8e2ad6;*/
}

.main {
    padding: 25px;
    /*border: dashed #1a83ff;*/
    overflow: auto;
}

a {
    text-decoration: none;
    color: #8e2ad6;
    /*border-bottom: 1px dotted;*/
}

.subtitle-link {
    font-size: 15px;
    margin: 5px;
    text-decoration: none;
    /*color: deepskyblue;*/
    border-bottom: 1px dotted;
}

/*.site-header{*/
/*  display: flex;*/
/*  align-items: center;*/
/*  justify-content: center;*/
/*  margin-bottom: 20px;*/
/*  background: #ffffff;*/
/*  width: 100%;*/
/*  height: 50px;*/
/*  box-shadow: 0 2px 4px 0 #e3e9f3;*/
/*  border-radius: 3px;*/
/*  !*line-height: 18px;*!*/
/*}*/

.login-form {
    font-size: 1.2em;
    width: 100%;
    max-width: 500px;
    padding: 15px;
    margin: auto;
}

.addStudent-form {
    font-size: 1em;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin-left: 0;
    flex-direction: row;
    display: flex;
    position: relative;
}

#addStudentInside-form {
    font-size: 1em;
    width: 100%;
    max-width: 100%;
    padding: 0px;
    margin-left: 5vw;
}
